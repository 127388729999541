/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText, Button, Card, Form} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    hr: "hr",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components);
  if (!Form) _missingMdxReference("Form", false);
  if (!Form.TextArea) _missingMdxReference("Form.TextArea", true);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.TextArea label=\"Quotes\" placeholder=\"Aren't you a little short for a stormtrooper?\" />\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "label-counter-and-placeholder",
    style: {
      position: "relative"
    }
  }, "Label, Counter, and Placeholder", React.createElement(_components.a, {
    href: "#label-counter-and-placeholder",
    "aria-label": "label counter and placeholder permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels should be used to describe the information we're asking the user to give us. Placeholders should be used to show an example of the content we're asking the user for. If we use placeholders as labels, once the user starts typing into the field, they lose the description of what we're asking for."), "\n", React.createElement(Banner, {
    status: "success",
    title: "Do"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.TextArea label=\"Quotes\" placeholder=\"Aren't you a little short for a stormtrooper?\" />\n</Form>\n")), "\n", React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.TextArea label=\"\" placeholder=\"Quotes\" />\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "label-help",
    style: {
      position: "relative"
    }
  }, "Label Help", React.createElement(_components.a, {
    href: "#label-help",
    "aria-label": "label help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels can have a help icon with a ", React.createElement(_components.a, {
    href: "/components/tooltip"
  }, "tooltip"), " to provide additional context to a label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.TextArea\n\t\tlabel=\"Input Label\"\n\t\tlabelProps={{\n\t\t\thelp: \"This is help text\"\n\t\t}}\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "required-and-optional",
    style: {
      position: "relative"
    }
  }, "Required and Optional", React.createElement(_components.a, {
    href: "#required-and-optional",
    "aria-label": "required and optional permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A visual indicator can be applied on a Textarea's label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.TextArea\n\t\tlabel=\"Required Label\"\n\t\tlabelProps={{\n\t\t\trequired: true\n\t\t}}\n\t/>\n\t<Form.TextArea\n\t\tlabel=\"Optional Label\"\n\t\tlabelProps={{\n\t\t\toptional: true\n\t\t}}\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "counter",
    style: {
      position: "relative"
    }
  }, "Counter", React.createElement(_components.a, {
    href: "#counter",
    "aria-label": "counter permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Counter can be displayed when there is a ", React.createElement(_components.code, null, "maxLength"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.TextArea\n\t\tlabel=\"Counter Example\"\n\t\tmaxLength={200}\n\t\tshowCounter\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "states",
    style: {
      position: "relative"
    }
  }, "States", React.createElement(_components.a, {
    href: "#states",
    "aria-label": "states permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Headline el=\"div\" className=\"m-b-4\" size=\"small\">Textarea with Description</Headline>\n\t<Form.TextArea label=\"Quotes\" placeholder=\"Aren't you a little short for a stormtrooper?\" description=\"Description goes here...\"/>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Headline el=\"div\" className=\"m-b-4\" size=\"small\">Disabled Textarea</Headline>\n\t<Form.TextArea label=\"Quotes\" placeholder=\"Aren't you a little short for a stormtrooper?\" disabled />\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Headline el=\"div\" className=\"m-b-4\" size=\"small\">Readonly Textarea</Headline>\n\t<Form.TextArea label=\"Quotes\" placeholder=\"Aren't you a little short for a stormtrooper?\" readOnly />\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Headline el=\"div\" className=\"m-b-4\" size=\"small\">Error Textarea</Headline>\n\t<Form.TextArea label=\"Quotes\" placeholder=\"Aren't you a little short for a stormtrooper?\" readOnly error=\"This field is required.\" />\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "TextAreas should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Be clearly labeled so it’s obvious to the user what they should enter into the field"), "\n", React.createElement(_components.li, null, "Only ask for information that’s really needed"), "\n", React.createElement(_components.li, null, "Validate input as soon as merchant has finished interacting with a field (but not before)"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most of the content guidelines from ", React.createElement(_components.a, {
    href: "/patterns/forms/#content-guidelines"
  }, "Forms"), " applies to the Textarea."), "\n", React.createElement(_components.h2, {
    id: "use-a-label-for-all-input-fields",
    style: {
      position: "relative"
    }
  }, "Use a label for all input fields", React.createElement(_components.a, {
    href: "#use-a-label-for-all-input-fields",
    "aria-label": "use a label for all input fields permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A label is a short, meaningful description that clearly indicates what the user is expected to enter. Labels should be 1 to 3 words and written in title case. Labels should primarily be nouns. Avoid using labels as CTAs. A label is not inline help and shouldn’t be used to provide instruction."), "\n", React.createElement(_components.h2, {
    id: "follow-capitalization-rules",
    style: {
      position: "relative"
    }
  }, "Follow capitalization rules", React.createElement(_components.a, {
    href: "#follow-capitalization-rules",
    "aria-label": "follow capitalization rules permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Input labels should be written in title case."), "\n", React.createElement(_components.h2, {
    id: "avoid-using-placeholder-text",
    style: {
      position: "relative"
    }
  }, "Avoid using placeholder text", React.createElement(_components.a, {
    href: "#avoid-using-placeholder-text",
    "aria-label": "avoid using placeholder text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Placeholder text has several accessibility issues:"), "\n", React.createElement(_components.p, null, "Text has low contrast which makes the text hard to read"), "\n", React.createElement(_components.p, null, "Text disappears as soon as the user starts typing"), "\n", React.createElement(_components.p, null, "Can’t accommodate additional context due to limited space"), "\n", React.createElement(_components.p, null, "Unreliable for screen readers"), "\n", React.createElement(_components.p, null, "Use inline help to provide hints, formatting, and requirements"), "\n", React.createElement(_components.h2, {
    id: "show-hints-formatting-and-requirements",
    style: {
      position: "relative"
    }
  }, "Show hints, formatting, and requirements", React.createElement(_components.a, {
    href: "#show-hints-formatting-and-requirements",
    "aria-label": "show hints formatting and requirements permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Inline help should explain a feature or the outcome of the actions the user is about to take."), "\n", React.createElement(_components.p, null, "The description should adapt to the situation and context. The guidance could be focused on what is needed, or it could describe how to enter it."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form.TextArea, {
    label: "Job Description",
    placeholder: "",
    description: "Description will be visible to the customer"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form.TextArea, {
    label: "Job Description",
    placeholder: "",
    description: "Enter a description"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "keep-overlay-help-short-simple-and-scannable",
    style: {
      position: "relative"
    }
  }, "Keep overlay help short, simple, and scannable", React.createElement(_components.a, {
    href: "#keep-overlay-help-short-simple-and-scannable",
    "aria-label": "keep overlay help short simple and scannable permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Overlay help should be short and easily scannable, no longer than a single phrase or short sentence."), "\n", React.createElement(_components.p, null, "Avoid repeating the UI text. Use sentence case, and only include period if more than one sentence is used."), "\n", React.createElement(_components.p, null, "For more complex or important information, consider another method of presentation, e.g., inline help or a link to the knowledge base."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form.TextArea, {
    label: "Regional settings",
    placeholder: "",
    labelProps: {
      help: "Regional settings are numbers, dates, and currencies related to your region"
    }
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form.TextArea, {
    label: "Regional settings",
    placeholder: "",
    labelProps: {
      help: "The numbers, dates, and currencies related to your region are important because they can affect the way that your organization will make decisions for that region. Choose a regional setting that makes the most sense for your area."
    }
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-assets",
    style: {
      position: "relative"
    }
  }, "Related Assets", React.createElement(_components.a, {
    href: "#related-assets",
    "aria-label": "related assets permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "components",
    style: {
      position: "relative"
    }
  }, "Components", React.createElement(_components.a, {
    href: "#components",
    "aria-label": "components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To build a full form, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " component."), "\n", React.createElement(_components.li, null, "For a single line of text, use an ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/input/"
  }, "Input")), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Form"), " design pattern for how related controls are ordered."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We recommend using the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " shorthand component ", React.createElement(_components.code, null, "<Form.TextArea />"), ". It automatically provide the correct Form grouping structure and spacing. You can import the standalone component for custom Form layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { TextArea } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
